import FirebaseConfig from 'configs/FirebaseConfig'
import {
  APPLY_SETTING_TYPE,
  ACTION_NAME,
  LICENSES,
  CONTENT_TYPE,
  ERROR_MESSAGE,
  USER_ROLE_MANGEMENT_MSG,
} from 'constants/Constant'
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions'
import moment from 'moment'
import axiosCF from 'utils/axios'

 //connectFunctionsEmulator(getFunctions(), '127.0.0.1', '5001')

function getUserList(tenantId) {
  return new Promise(function (resolve, _reject) {
    let getFireBaseUserList = httpsCallable(getFunctions(), 'getUserList')
    getFireBaseUserList({ tenantId: tenantId }).then((res) => {
      resolve(res)
    })
  })
}

function DeleteUser(userIdList, tenantId) {
  return new Promise(function (resolve, _reject) {
    let getFireBaseUserList = httpsCallable(getFunctions(), 'deleteUsers')

    getFireBaseUserList({ userIdList, tenantId }).then((res) => {
      resolve(res)
    })
  })
}

function createFirebaseUser(userdetails) {
  return new Promise(function (resolve, _reject) {
    axiosCF
      .post('createFirebaseUser', userdetails)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        resolve(err.response)
      })
  })
}
function editFirebaseUser(userDetails) {
  return new Promise(function (resolve, _reject) {
    let editFirebaseUser = httpsCallable(getFunctions(), 'editFirebaseUser')
    editFirebaseUser(userDetails).then((res) => {
      resolve(res)
    })
  })
}
function getDocsFromAuthentication(uid, getPerFir) {
  return new Promise(function (resolve, _reject) {
    const getFirebaseUserByUid = httpsCallable(getFunctions(), 'getMappedTenantId')
    getFirebaseUserByUid({ uid, getPerFir }).then((res) => {
      resolve(res)
    })
  })
}

function setIsLoggedInFirstTime(uid) {
  return new Promise(function (resolve, _reject) {
    const updateAuthenticationByUid = httpsCallable(getFunctions(), 'updateAuthenticationByUid')
    updateAuthenticationByUid(uid).then((res) => {
      resolve(res)
    })
  })
}

const getZebraDNAServerVersion = () => {
  let sVersionFun = httpsCallable(getFunctions(), 'getZebraDNAServerVersion')
  return sVersionFun()
}

const encrypt = (data) => {
  let encryptFun = httpsCallable(getFunctions(), 'validate')
  return encryptFun(data)
}

const getEnrollmentBarcode = (profileJson) => {
  let addMessage = httpsCallable(getFunctions(), 'generateEnrollmentBarcode')
  return addMessage(profileJson)
}

const getProfileData = (tenantId, profileId, type, keys) => {
  return new Promise(function (resolve, _reject) {
    let addMessage = httpsCallable(getFunctions(), 'profileData')
    addMessage({ tenantId, profileId, type, keys })
      .then((res) => {
        //console.log('got response', res)
        resolve(res.data)
      })
      .catch((err) => {
        console.log('Error no profile found', err)
        resolve()
      })
  })
}

const enrollDevices = (deviceUdiArray, tenantId) => {
  let data = {}
  data['userId'] = tenantId
  data['deviceUdiArray'] = deviceUdiArray
  console.log('inside enrollDevices, data: ', data)
  let addMessage = httpsCallable(getFunctions(), 'updateFOTAEnrollment')
  return addMessage(data)
}

async function deleteGroupFromDB(record, tenantId) {
  return new Promise(function (resolve, _reject) {
    let data = {}
    data['userId'] = tenantId
    data['groupId'] = record.id
    data['groupName'] = record.groupName

    let addMessage = httpsCallable(getFunctions(), 'deleteGroup')
    addMessage(data)
      .then((res) => {
        console.log('got response', res)
        resolve(res.data.status)
      })
      .catch((err) => {
        console.log('Error deleting groups', err)
      })
  })
}

const renameGroup = async (record, updatedGroupName, existingList, tenantId) => {
  return new Promise(function (resolve, _reject) {
    let data = {}
    data['userId'] = tenantId
    data['groupId'] = record.id
    data['groupName'] = record.groupName
    data['updatedGroupName'] = updatedGroupName
    let groupsExisting = []
    if (existingList && existingList.length > 0) {
      existingList.map((item) => {
        groupsExisting.push(item.groupName)
      })
    }
    if (groupsExisting && groupsExisting.indexOf(updatedGroupName) !== -1) {
      resolve('Duplicate')
    } else {
      let renameGroupFirestore = httpsCallable(getFunctions(), 'renameGroup')
      renameGroupFirestore(data)
        .then((res) => {
          console.log('got response', res)
          resolve(res.data.status)
        })
        .catch((err) => {
          console.log('Error renaming groups', err)
        })
    }
  })
}

const addNewGroup = async (groupName, existingList, tenantId) => {
  return new Promise(function (resolve, _reject) {
    if (typeof groupName === 'undefined' || groupName === '') {
      resolve('Empty')
    }
    let groupsExisting = []
    if (existingList && existingList.length > 0) {
      existingList.map((item) => {
        groupsExisting.push(item.groupName)
      })
    }
    if (groupsExisting && groupsExisting.indexOf(groupName) !== -1) {
      resolve('Duplicate')
    } else {
      let groupData = {}
      if (groupName) {
        groupData.groupName = groupName
        groupData.createdDateTime = moment().valueOf()
      }
      let data = {}
      data['userId'] = tenantId
      data['groupData'] = groupData
      let addGroupFirestore = httpsCallable(getFunctions(), 'addGroup')
      addGroupFirestore(data)
        .then((res) => {
          console.log('got response', res)
          resolve(res.data.status)
        })
        .catch((err) => {
          console.log('Error renaming groups', err)
          resolve()
        })
    }
  })
}

function deviceAction(deviceUdi, name, type, selectedRecord, tenantId, appliedLicense = []) {
  return new Promise(function (resolve) {
    let deploymentData = {
      deviceUdi: deviceUdi,
      name: name,
      createdType: type,
      sessionStartTime: Date.now(),
      appliedLicense,
    }
    if (name === 'RESET_DEVICE_PASSCODE') {
      // deploymentData.passcodeArray = selectedRecord.passcodeArray
      deploymentData.devicePasscode = selectedRecord.devicePasscode
    }
    if (name === 'UPDATE_DEVICE_NAME') {
      deploymentData.deviceName = selectedRecord
    }
    if (name === 'SUBMIT_XML_DEVICE') {
      deploymentData.submitXmlData = selectedRecord.submitXmlData
    }
    if (name === 'UPDATE_CLIENT_DEVICE') {
      deploymentData.checkVersionToUpdate = selectedRecord.checkVersionToUpdateSingle
    }
    let data = {}
    data['userId'] = tenantId
    data['deploymentData'] = deploymentData
    let deviceData
    if (name === ACTION_NAME.START_REMOTE_CONTROL) {
      deviceData = httpsCallable(getFunctions(), 'getTwilioToken')
    } else {
      deviceData = httpsCallable(getFunctions(), 'executeDeviceAction')
    }
    deviceData(data)
      .then((res) => {
        console.log(res, 'Inside deviceAction action response')
        resolve(res.data)
      })
      .catch((err) => {
        resolve(err)
        console.log('Error in device action', err)
      })
  })
}

const viewEnrollmentBarcode = (profileData) => {
  let addMessage = httpsCallable(getFunctions(), 'viewEnrollmentBarcode')
  return addMessage(profileData)
}

function bulkDeviceAction(
  deviceUdiArr,
  name,
  type,
  tenantId,
  checkVersionToUpdate,
  appliedLicense = [],
  submitXmlData = ''
) {
  return new Promise(function (resolve) {
    let deploymentData = {
      deviceUdiArray: deviceUdiArr,
      name: name,
      createdType: type,
      checkVersionToUpdate,
      appliedLicense,
    }
    let data = {}
    if (submitXmlData.length) {
      deploymentData['submitXmlData'] = submitXmlData
    }
    data['userId'] = tenantId
    data['deploymentData'] = deploymentData
    let deviceData = httpsCallable(getFunctions(), 'executeBulkDeviceAction')
    deviceData(data)
      .then((res) => {
        console.log(res.data)
        resolve(res.data.status)
      })
      .catch((err) => {
        resolve(err)
        console.log('Error in bulk device action', err)
      })
  })
}

const deleteEnrollmentProfile = (profileData, tenantId) => {
  return new Promise(function (resolve, _reject) {
    let data = {}
    data['userId'] = tenantId
    data['profileData'] = profileData
    let addMessage = httpsCallable(getFunctions(), 'deleteEnrollmentProfile')
    addMessage(data)
      .then((res) => {
        console.log(res)
        resolve(res)
      })
      .catch((err) => {
        console.log('Error while deleting the profile: ' + err)
        resolve(err)
      })
  })
}

const deleteBulkEnrollmentProfiles = (profileData, tenantId) => {
  return new Promise(function (resolve, _reject) {
    let data = {}
    data['userId'] = tenantId
    data['profileData'] = profileData
    let addMessage = httpsCallable(getFunctions(), 'deleteBulkEnrollmentProfiles')
    addMessage(data)
      .then((res) => {
        console.log(res)
        resolve(res)
      })
      .catch((err) => {
        console.log('Error while deleting bulk enrollment profiles: ' + err)
        resolve(err)
      })
  })
}

const createDeviceSetting = (settingJson) => {
  let addMessage = httpsCallable(getFunctions(), 'saveDeviceSettings')
  return addMessage(settingJson).catch((err) => {
    console.log('Error in processing Device Settings: Error ' + err)
  })
}

const createJobForDeviceSetting = (profileData, setupType, tenantId) => {
  return new Promise(function (resolve) {
    let data = { ...profileData }
    data['userId'] = tenantId
    if (setupType === APPLY_SETTING_TYPE.GUIDED_SETUP) {
      let deviceData = httpsCallable(getFunctions(), 'applyDeviceSettings')
      deviceData(data)
        .then((res) => {
          console.log('action response', res)
          resolve(res.data)
        })
        .catch((err) => {
          console.log('Error in createJobForDeviceSetting err: ', err)
          resolve(err)
        })
    } else {
      let deviceData = httpsCallable(getFunctions(), 'applyAdvancedDeviceSettings')
      deviceData(data)
        .then((res) => {
          console.log('action response', res)
          resolve(res.data)
        })
        .catch((err) => {
          console.log('Error in createJobForDeviceSetting err: ', err)
          resolve(err)
        })
    }
  })
}

const createJobForAppInstallation = (profileData, tenantId) => {
  return new Promise(function (resolve) {
    let data = { ...profileData }
    data['userId'] = tenantId
    let deviceData = httpsCallable(getFunctions(), 'saveAppInstallation')
    deviceData(data)
      .then((res) => {
        console.log('action response', res)
        resolve(res.data)
      })
      .catch((err) => {
        console.log('Error in createJobForAppInstallation err: ', err)
        resolve(err)
      })
  })
}

const createJobForAppUninstallation = (profileData, tenantId) => {
  return new Promise(function (resolve) {
    let data = { ...profileData }
    data['userId'] = tenantId
    let deviceData = httpsCallable(getFunctions(), 'uninstallApplication')
    deviceData(data)
      .then((res) => {
        console.log('action response', res)
        resolve(res.data)
      })
      .catch((err) => {
        console.log('Error in createJobForAppUninstallation err: ', err)
        resolve(err)
      })
  })
}

const deleteDeviceSettings = (profileDeploymentIds, tenantId) => {
  return new Promise(function (resolve, _reject) {
    let data = {}
    data['userId'] = tenantId
    data['profileDeploymentIds'] = profileDeploymentIds
    let addMessage = httpsCallable(getFunctions(), 'deleteDeviceSettingProfiles')
    addMessage(data)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        resolve(err)
      })
  })
}

const processLgeUpdate = (jobJson) => {
  let addMessage = httpsCallable(getFunctions(), 'updateDevice')
  return addMessage(jobJson).catch((err) => {
    console.log('Error in processing Android Updates job: Error ' + err)
  })
}

const createAppSetup = (settingJson) => {
  return new Promise((resolve) => {
    let addMessage = httpsCallable(getFunctions(), 'saveAppSetup')
    addMessage(settingJson)
      .then((res) => {
        console.log('inside createAppSetup:', res)
        resolve(res)
      })
      .catch((err) => {
        console.log('Error in createAppSetup: Error ' + err)
        resolve(err)
      })
  })
}

const createJobForAppSetup = (profileData, tenantId) => {
  return new Promise(function (resolve) {
    let data = { ...profileData }
    data['userId'] = tenantId
    console.log('Inside createJobForAppSetup, data: ' + JSON.stringify(data))
    let deviceData = httpsCallable(getFunctions(), 'applyAppSetupProfile')
    deviceData(data)
      .then((res) => {
        console.log('action response', res)
        resolve(res)
      })
      .catch((err) => {
        console.log('Error in createJobForAppSetup err: ', err)
        resolve(err)
      })
  })
}

const createAdvancedDeviceSetting = async (settingJson) => {
  return new Promise(function (resolve, _reject) {
    let addMessage = httpsCallable(getFunctions(), 'saveAdvancedDeviceSettings')
    addMessage(settingJson)
      .then((res) => {
        console.log('inside createAdvancedDeviceSetting, res: ', res)
        return resolve(res)
      })
      .catch((err) => {
        console.log('Error in processing Advanced Device Settings: ' + err)
        return resolve(err)
      })
  })
}

const flexeraLicensesRefresh = (tenantId) => {
  let data = {}
  data['userId'] = tenantId
  let addMessage = httpsCallable(getFunctions(), 'flexeraLicensesRefresh')
  return addMessage(data).catch((err) => {
    console.log('Error in getting data for sync license Error ' + err)
    return err
  })
}

const fetchLicenseSummary = (tenantId) => {
  let data = {}
  data['userId'] = tenantId
  let res = httpsCallable(getFunctions(), 'fetchLicenseSummary')
  return res(data).catch((err) => {
    console.log('Error in getting data for license Error ' + err)
    return err
  })
}

const updateDeviceLicenseStatus = (deviceUdis, tenantId) => {
  let data = {}
  data['userId'] = tenantId
  data['deviceUdiArray'] = deviceUdis
  let res = httpsCallable(getFunctions(), 'updateDeviceLicenseStatus')
  return res(data).catch((err) => {
    console.log('Error in getting data for license Error ' + err)
    return err
  })
}

async function getDeploymentDetails(tenantId) {
  let data = {}
  data['userId'] = tenantId
  return new Promise(function (resolve) {
    let res = httpsCallable(getFunctions(), 'getDeploymentList')

    return res(data)
      .then((res) => {
        console.log('got response from saveDeviceSettings, Res: ', JSON.stringify(res.data))
        resolve(res)
      })
      .catch((err) => {
        console.log('Error in getting data for license Error ' + err)
        resolve(err)
      })
  })
}

async function getDeploymentListAfterStatusProcessDetails(tenantId) {
  let data = {}
  data['userId'] = tenantId
  return new Promise(function (resolve) {
    let res = httpsCallable(getFunctions(), 'getDeploymentListAfterStatusProcess')

    return res(data)
      .then((res) => {
        console.log(
          'got response from getDeploymentListAfterStatusProcessDetails, Res: ',
          JSON.stringify(res.data)
        )
        resolve(res)
      })
      .catch((err) => {
        console.log('Error in getting data ' + err)
        resolve(err)
      })
  })
}

const getProcessedDeviceStatusInDeployment = (data, tenantId) => {
  data['userId'] = tenantId
  let res = httpsCallable(getFunctions(), 'getProcessedDeviceStatusInDeployment')
  return res(data).catch((err) => {
    console.log('Error in getting data. Error ' + err)
    return err
  })
}

const getDeviceDetailsInSetting = (data, tenantId) => {
  data['userId'] = tenantId
  let res = httpsCallable(getFunctions(), 'getDeviceStatusInDeployment')
  return res(data).catch((err) => {
    console.log('Error in getting data for license Error ' + err)
    return err
  })
}

const licenseWrapper = (tenantId, source, data = {}) => {
  data['source'] = source
  data['userId'] = tenantId
  return new Promise(function (resolve, _reject) {
    axiosCF
      .post('licenseWrapper', data)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        resolve({ status: 'error', message: 'Error, kindly try again' })
      })
  })
}


const deleteAssignedDevice = (devices, tenantId, emailId) => {
  return new Promise(function (resolve, _reject) {
    let data = { userId: tenantId, devices, emailId }
    let addMessage = httpsCallable(getFunctions(), 'deleteDeploymentAssignedDevice')
    addMessage(data)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        console.log('Error while deleting deployment assigned device: ' + err)
        resolve(err)
      })
  })
}

const reapplyAssignedDevice = (devices, tenantId, emailId) => {
  return new Promise(function (resolve, _reject) {
    let data = { devices, userId: tenantId, emailId }
    let addMessage = httpsCallable(getFunctions(), 'reapplyDeploymentAssignedDevice')
    addMessage(data)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        console.log('Error while reapply deployment assigned device: ' + err)
        resolve(err)
      })
  })
}

/**
 *
 * @param {*} deploymentId
 * @param {*} deviceId
 * @returns Deleting device from app setup
 */
const deleteAssignedDeviceFromApp = (
  deploymentId,
  deviceId,
  appInfo,
  profileId,
  userId,
  category
) => {
  return new Promise(function (resolve, _reject) {
    let data = { userId, deploymentId, deviceId, ...appInfo, profileId, category }

    let addMessage = httpsCallable(getFunctions(), 'deleteDeploymentAssignedDeviceFromApp')
    addMessage(data)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        console.log('Error while deleting deployment assigned device: ' + err)
        resolve(err)
      })
  })
}

const reapplyAssignedDeviceFromApp = (payload) => {
  return new Promise(function (resolve, _reject) {
    let addMessage = httpsCallable(getFunctions(), 'reapplyDeploymentAssignedDeviceFromApp')
    addMessage(payload)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        console.log('Error while reapply deployment assigned device: ' + err)
        resolve(err)
      })
  })
}

const allocateLicenses = async (
  formData,
  selectedDevices,
  deviceList,
  totalLicenseCount,
  tenantId,
  featureName
) => {
  return new Promise(function (resolve, _reject) {
    let data = {}
    data['userId'] = tenantId
    data['formData'] = formData
    data['selectedDevices'] = selectedDevices
    data['devices'] = deviceList
    data['featureName'] = featureName

    axiosCF
      .post('allocateLicenses', data)
      .then((res) => {
        console.log('Inside allocateLicenses, .then' + JSON.stringify(res))
        resolve(res)
      })
      .catch((err) => {
        console.log('Inside allocateLicenses, .catch' + JSON.stringify(err.message))
        resolve(err)
      })
  })
}

const saveDeviceSettingWallpapers = (data) => {
  return new Promise(function (resolve, _reject) {
    let addMessage = httpsCallable(getFunctions(), 'uploadDeviceSettingWallpapers')
    addMessage(data)
      .then((res) => {
        console.log(
          'Inside saveDeviceSettingWallpapers: response from server: ' + JSON.stringify(res)
        )
        resolve(res)
      })
      .catch((err) => {
        console.log(
          'Inside saveDeviceSettingWallpapers: Error while allocating: ' + JSON.stringify(err)
        )
        resolve(err)
      })
  })
}

const getAccessToken = (tenantId) => {
  let data = {}
  data['userId'] = tenantId
  let addMessage = httpsCallable(getFunctions(), 'getLgeAccessToken')
  return addMessage(data).catch((err) => {
    console.log('Error while getting access token, Error ' + err)
    return err
  })
}

async function getDeploymentDetailsList(profileType, tenantId) {
  let data = {}
  data['userId'] = tenantId
  data.profileType = profileType
  return new Promise(function (resolve) {
    let res = httpsCallable(getFunctions(), 'getDeploymentDetailsList')

    return res(data)
      .then((res) => {
        console.log('got response from getDeploymentDetailsList, Res: ', JSON.stringify(res.data))
        if (res.data && res.data.status === 'SUCCESS' && res.data.result) {
          resolve(res.data.result)
        } else {
          resolve([])
        }
      })
      .catch((err) => {
        console.log('Error in getting data form getDeploymentDetailsList Error ' + err)
        resolve([])
      })
  })
}

async function getDeploymentDetailsListWithUpdatedStatuses(profileType, tenantId) {
  let data = {}
  data['userId'] = tenantId
  data.profileType = profileType
  return new Promise(function (resolve) {
    let res = httpsCallable(getFunctions(), 'getDeploymentDetailsListWithUpdatedStatuses')

    return res(data)
      .then((res) => {
        res.data.status && res.data.status === 'SUCCESS' && res.data.result
          ? resolve(res.data.result)
          : resolve([])
      })
      .catch((err) => {
        console.log('Error in getting data ' + err)
        resolve([])
      })
  })
}
const collectDeveloperData = async (developerData, tenantId) => {
  let data = {}
  data['userId'] = tenantId
  data['devData'] = developerData
  let addMessage = httpsCallable(getFunctions(), 'collectDeveloperData')
  return addMessage(data).catch((err) => {
    console.log('Error while getting access token, Error ' + err)
    return err
  })
}

const getSignedUrlForUpload = async (fileName, tenantId) => {
  let data = {}
  data['userId'] = tenantId
  data['fileName'] = fileName

  let addMessage = httpsCallable(getFunctions(), 'getV4UploadSignedUrl')
  return addMessage(data).catch((err) => {
    console.log('Error while getting the signed URL for upload, Error ' + JSON.stringify(err))
    return { status: 'Error' }
  })
}

const createOrUpdateAppProfile = async (
  request,
  profileId,
  isEdit = false,
  tenantId,
  isAddVersion = false
) => {
  let data = {}
  data['userId'] = tenantId
  data['payload'] = request
  if (profileId && profileId != '') {
    data['profileId'] = profileId
  }
  data['isEdit'] = isEdit
  data['isAddVersion'] = isAddVersion

  let addMessage = httpsCallable(getFunctions(), 'createOrUpdateAppProfile')
  return addMessage(data).catch((err) => {
    console.log('Error while creating/updating the app profile, Error ' + JSON.stringify(err))
    return { status: 'Error' }
  })
}

const deleteFileFromStorage = async (filePath, tenantId) => {
  let data = {}
  data['userId'] = tenantId
  data['filePath'] = filePath

  let addMessage = httpsCallable(getFunctions(), 'deleteFile')
  return addMessage(data).catch((err) => {
    console.log('Error while deleting the file from storage, Error ' + JSON.stringify(err))
    return { status: 'Error' }
  })
}

const closeRCRoom = async (deploymentId, roomSID, udi, tenantID) => {
  let data = {}
  data['deploymentId'] = deploymentId
  data['roomSID'] = roomSID
  data['udi'] = udi
  data['tenantID'] = tenantID
  data['sessionEndTime'] = Date.now()
  let addMessage = httpsCallable(getFunctions(), 'terminateRoom')
  return addMessage(data)
    .then((res) => {
      console.log('Room termination res' + JSON.stringify(res))
      return res
    })
    .catch((err) => {
      console.log('Error while terminating room, Error ' + JSON.stringify(err))
      return { success: false }
    })
}

const uploadFileToStorage = async (data) => {
  let addMessage = httpsCallable(getFunctions(), 'getZebraAppsUploadSignedUrl')
  return addMessage(data).catch((err) => {
    console.log('Error while getting the signed URL for upload, Error ' + JSON.stringify(err))
    return { status: 'Error' }
  })
}

const createDeploymentForBulkDeviceAutoNaming = (payload) => {
  return new Promise(function (resolve) {
    let cloudCall = httpsCallable(getFunctions(), 'createDeploymentForBulkDeviceAutoNaming')
    cloudCall(payload)
      .then((res) => {
        console.log('got response from getDeploymentDetailsList, Res: ', JSON.stringify(res.data))
        if (res.data && res.data.status === 'success') {
          resolve({ success: true })
        } else {
          if (res.data && res.data.result && res.data.result === LICENSES.LICENSE_ERROR) {
            resolve({ success: false, result: res.data.result })
          } else {
            resolve({ success: false })
          }
        }
      })
      .catch((err) => {
        console.log('Error in getting data form getDeploymentDetailsList Error ' + err)
        resolve({ success: false })
      })
  })
}

const getZebraInfoTokens = (tenantId) => {
  return new Promise(function (resolve, reject) {
    var data = {}
    data['userId'] = tenantId

    console.log(' data : ==> ', data)
    axiosCF
      .post('getZebraInfoTokens', data)
      .then((res) => {
        console.log(res)
        resolve(res)
      })
      .catch((err) => {
        console.log('Error in getZebraInfoTokens: ', err.response)
        let errData = err.response
          ? err.response
          : { status: 'error', error: 'Error in getZebraInfoTokens' }
        resolve(errData)
      })
  })
}
const saveZebraServicesData = (payload) => {
  return new Promise(function (resolve, reject) {
    console.log(' data : ==> ', payload)
    axiosCF
      .post('connectToZebraServices', payload)
      .then((res) => {
        console.log(res)
        resolve(res)
      })
      .catch((err) => {
        console.log('Error in saveZebraServicesData: ', err.response)
        let errData = err.response
          ? err.response
          : { status: 'error', error: 'Error in saveZebraServicesData' }
        resolve(errData)
      })
  })
}

const expireElsEnrollments = (expireEnrollmentArr, tenantId) => {
  return new Promise(function (resolve, reject) {
    let data = {}
    data['userId'] = tenantId
    data['expireEnrollmentArr'] = expireEnrollmentArr
    return axiosCF
      .post('expireElsEnrollments', data)
      .then((res) => {
        console.log('done expiring')
        resolve(res)
      })
      .catch((err) => {
        console.log('Error while expiring: ', err.message)
        resolve({ status: 'error', result: ERROR_MESSAGE.ENROLLMENT_EXPIRE_ERROR })
      })
  })
}

const deleteElsEnrollments = (deleteEnrollmentArr, tenantId) => {
  return new Promise(function (resolve, reject) {
    let data = {}
    data['userId'] = tenantId
    data['deleteEnrollmentArr'] = deleteEnrollmentArr
    return axiosCF
      .post('deleteElsEnrollments', data)
      .then((res) => {
        console.log('done deleting')
        resolve(res)
      })
      .catch((err) => {
        console.log('Error while deleting: ', err.message)
        resolve({ status: 'error', result: ERROR_MESSAGE.ENROLLMENT_DELETE_ERROR })
      })
  })
}

// const searchWhitelistedEmail = (tenantId) => {
//   return new Promise(function (resolve, reject) {
//     let data = {}
//     data['userId'] = tenantId
//     return axiosCF
//       .post('searchWhitelistedEmail', data)
//       .then((res) => {
//         console.log("done searching")
//         resolve(res)
//       })
//       .catch((err) => {
//         console.log("Error while searching: ", err.message)
//         resolve({ status: 'error' })
//       })
//   })
// }

const updateDeviceUsers = (tenantId) => {
  return new Promise(function (resolve, reject) {
    console.log('inside updateDeviceUsers')
    let data = {}
    data['userId'] = tenantId
    return axiosCF
      .post('updateDeviceUsers', data)
      .then((res) => {
        console.log('done updating')
        resolve(res)
      })
      .catch((err) => {
        console.log('Error while updating: ', err.message)
        resolve({ status: 'error', result: ERROR_MESSAGE.DEVICE_USER_UPDATE_ERROR })
      })
  })
}

const initiateBackgroundJob = (data, tenantId) => {
  console.log('initiateBackgroundJob, jobType ', data.jobType)
  return new Promise((resolve) => {
    data['userId'] = tenantId
    return axiosCF
      .post('initiateBackgroundJob', data)
      .then((res) => {
        console.log('job response', res)
        resolve(res.data)
      })
      .catch((err) => {
        console.log('Error in initiateBackgroundJob err: ', err)
        resolve({ status: 'error' })
      })
  })
}

const getPermissions = (tenantId, uuid) => {
  return new Promise(function (resolve, reject) {
    console.log('inside getPermissions')
    let data = { userId: tenantId, uuid: uuid }
    return axiosCF
      .post('retriveUser', data)
      .then((res) => {
        console.log('Successfully fetched')
        resolve(res)
      })
      .catch((err) => {
        console.log('Error while fetching user permission: ', err.message)
        resolve({ status: 'error' })
      })
  })
}

const deleteRole = (roleData, tenantId) => {
  return new Promise(function (resolve, reject) {
    console.log('deleteRole ')
    let data = {}
    data['userId'] = tenantId
    data['data'] = roleData
    return axiosCF
      .post('deleteRole', data)
      .then((res) => {
        console.log('done deleting the role ', JSON.stringify(res.data))
        resolve(res.data)
      })
      .catch((err) => {
        console.log('Error while deleting: ', err.message)
        resolve({ status: 'error', result: USER_ROLE_MANGEMENT_MSG.ROLE_DELETE_ERROR })
      })
  })
}

// Wireless Insights APIs

const getWIDasboardDetails = (body, type) => {
  return new Promise(function (resolve, reject) {
  
    return axiosCF
      .post(`/getWIDasboardData?type=${type}`,body)
      .then((res) => {
        console.log('done updating')
        resolve(res)
      })
      .catch((err) => {
        console.log('Error while updating: ', err.message)
        resolve({ status: 'error', result: ERROR_MESSAGE.DEVICE_USER_UPDATE_ERROR })
      })
  })
}

const getWIDashboardTableData = (body) => {
  return new Promise(function (resolve, reject) {
    return axiosCF
      .post(`/getGroupTableData`,body)
      .then((res) => {
        console.log('done updating')
        resolve(res)
      })
      .catch((err) => {
        console.log('Error while updating: ', err.message)
        resolve({ status: 'error', result: ERROR_MESSAGE.DEVICE_USER_UPDATE_ERROR })
      })
  })
}

const getWifiGroupDetails = (body, type) => {
  return new Promise(function (resolve, reject) {
    return axiosCF
      .post(`/getWifiGroupDetails?type=${type}`,body)
      .then((res) => {
        console.log('done updating')
        resolve(res)
      })
      .catch((err) => {
        console.log('Error while updating: ', err.message)
        resolve({ status: 'error', result: ERROR_MESSAGE.DEVICE_USER_UPDATE_ERROR })
      })
  })
}

const getWifiDeviceDetails = (body, type, analysisType) => {
  return new Promise(function (resolve, reject) {
    return axiosCF
      .post(`/getWifiDeviceDetails?type=${type}&analysisType=${analysisType}`,body)
      .then((res) => {
        console.log('done updating')
        resolve(res)
      })
      .catch((err) => {
        console.log('Error while updating: ', err.message)
        resolve({ status: 'error', result: ERROR_MESSAGE.DEVICE_USER_UPDATE_ERROR })
      })
  })
}

const getCellularGroupDetails = (body, type) => {
  return new Promise(function (resolve, reject) {
    return axiosCF
      .post(`/getCellularGroupDetails?type=${type}`,body)
      .then((res) => {
        console.log('done updating')
        resolve(res)
      })
      .catch((err) => {
        console.log('Error while updating: ', err.message)
        resolve({ status: 'error', result: ERROR_MESSAGE.DEVICE_USER_UPDATE_ERROR })
      })
  })
}

const getCellularDeviceDetails = (body, type, analysisType) => {
  const url = analysisType?`/getCellularDeviceDetails?type=${type}&analysisType=${analysisType}`: `/getCellularDeviceDetails?type=${type}`
  
  return new Promise(function (resolve, reject) {
    return axiosCF
      .post(url,body)
      .then((res) => {
        console.log('done updating')
        resolve(res)
      })
      .catch((err) => {
        console.log('Error while updating: ', err.message)
        resolve({ status: 'error', result: ERROR_MESSAGE.DEVICE_USER_UPDATE_ERROR })
      })
  })
}

export {
  getUserList,
  DeleteUser,
  createFirebaseUser,
  getDocsFromAuthentication,
  setIsLoggedInFirstTime,
  getZebraDNAServerVersion,
  encrypt,
  getEnrollmentBarcode,
  addNewGroup,
  renameGroup,
  deleteGroupFromDB,
  viewEnrollmentBarcode,
  bulkDeviceAction,
  deviceAction,
  deleteEnrollmentProfile,
  deleteBulkEnrollmentProfiles,
  editFirebaseUser,
  createDeviceSetting,
  createJobForDeviceSetting,
  createJobForAppInstallation,
  createJobForAppUninstallation,
  deleteDeviceSettings,
  processLgeUpdate,
  createAppSetup,
  createJobForAppSetup,
  createAdvancedDeviceSetting,
  flexeraLicensesRefresh,
  fetchLicenseSummary,
  updateDeviceLicenseStatus,
  getDeploymentDetails,
  getDeviceDetailsInSetting,
  deleteAssignedDevice,
  reapplyAssignedDevice,
  allocateLicenses,
  saveDeviceSettingWallpapers,
  getAccessToken,
  enrollDevices,
  getDeploymentListAfterStatusProcessDetails,
  getProcessedDeviceStatusInDeployment,
  getDeploymentDetailsList,
  getDeploymentDetailsListWithUpdatedStatuses,
  collectDeveloperData,
  getSignedUrlForUpload,
  createOrUpdateAppProfile,
  deleteFileFromStorage,
  closeRCRoom,
  deleteAssignedDeviceFromApp,
  reapplyAssignedDeviceFromApp,
  uploadFileToStorage,
  createDeploymentForBulkDeviceAutoNaming,
  getProfileData,
  getZebraInfoTokens,
  expireElsEnrollments,
  deleteElsEnrollments,
  saveZebraServicesData,
  updateDeviceUsers,
  licenseWrapper,
  initiateBackgroundJob,
  getPermissions,
  deleteRole,
  getWIDasboardDetails,
  getWIDashboardTableData,
  getWifiGroupDetails,
  getWifiDeviceDetails,
  getCellularGroupDetails,
  getCellularDeviceDetails
}
